import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=316fb594"
import script from "./settings.vue?vue&type=script&lang=js"
export * from "./settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Printer: require('/usr/src/app/components/printer.vue').default,SyncDetails: require('/usr/src/app/components/sync-details.vue').default,Scanner: require('/usr/src/app/components/scanner.vue').default,WeighingScale: require('/usr/src/app/components/weighing-scale.vue').default,InternationalBancard: require('/usr/src/app/components/international-bancard.vue').default,Tidypay: require('/usr/src/app/components/tidypay.vue').default,Skyband: require('/usr/src/app/components/skyband.vue').default,Pinelabs: require('/usr/src/app/components/pinelabs.vue').default,Clover: require('/usr/src/app/components/clover.vue').default,Keyboard: require('/usr/src/app/components/keyboard.vue').default,OnlinePlatform: require('/usr/src/app/components/online-platform.vue').default,Import: require('/usr/src/app/components/import.vue').default,EmployeeList: require('/usr/src/app/components/employee-list.vue').default,FloorList: require('/usr/src/app/components/floor-list.vue').default,TableList: require('/usr/src/app/components/table-list.vue').default,TaxList: require('/usr/src/app/components/tax-list.vue').default,ChargeList: require('/usr/src/app/components/charge-list.vue').default,DiscountList: require('/usr/src/app/components/discount-list.vue').default,BrandList: require('/usr/src/app/components/brand-list.vue').default,CategoryList: require('/usr/src/app/components/category-list.vue').default,PriceCategoryList: require('/usr/src/app/components/price-category-list.vue').default})
